import { useState, useEffect } from "react";
import ButtonAppBar from "./appBar";
import { Link } from "react-router-dom";

function Posts() {
  const [isLoading, setLoading] = useState(true);
  const url =
    "https://modular.org.br/wp-json/wp/v2/posts";
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setPosts(result);
        setLoading(false);
      });
  }, [isLoading]);
  if (isLoading) {
    return <div>Carregando</div>;
  }
  console.log(posts);
  return (
    <div>
      <ButtonAppBar nome="Posts" />
      {posts.map((post) => (
        <div key={post.id}>
          <Link to={`post/${post.id}`}>{post.title.rendered}</Link>
          <p></p>
        </div>
      ))}
    </div>
  );
}

export default Posts;
