import { useState, useEffect } from "react";
import ButtonAppBar from "./appBar";
import { useParams } from "react-router-dom";

function Post() {
  const [post, setPost] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const { idDoPostParam } = useParams();
  const url =
    "https://modular.org.br/wp-json/wp/v2/posts/" + idDoPostParam;
  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setPost(result);
        setLoading(false);
      });
  }, [isLoading]);
  if (isLoading) {
    return <div>Carregando</div>;
  }
  return (
    <div>
      <ButtonAppBar nome="Post" />
        <div style={{ padding: "10px" }}>
          <div dangerouslySetInnerHTML={{__html: post.content.rendered }}></div>
        </div>
    </div>
  );
}

export default Post;
